/* **************************************** */
/* Add to cart custom button configurations */
/* **************************************** */

jQuery(document).ready(function ($) {
  // Listen for changes on variation select element
  $(".variations_form").on("woocommerce_variation_select_change", function () {
    // When variation is found
    $(".single_variation_wrap").on(
      "show_variation",
      function (event, variation) {
        // Check if the variation is in stock and backorders are not allowed
        if (variation.is_in_stock && !variation.is_backorder_allowed) {
          $(".single_add_to_cart_button").text("Add to Cart");
        } else if (!variation.is_in_stock) {
          $(".single_add_to_cart_button").text("Out of Stock");
        } else if (variation.is_in_stock && variation.is_backorder_allowed) {
          $(".single_add_to_cart_status_button").text("Available on backorder");
        }
      }
    );
  });

  // To ensure the button text is reset if no variation is selected
  $(".variations_form").on("woocommerce_variation_select_reset", function () {
    $(".single_add_to_cart_button").text("Select Options");
  });
});

/* **************************************** */
/* backorder limit add to cart configurations */
/* **************************************** */
jQuery(document).ready(function ($) {
  // console.log("Script loaded");

  function checkBackorderLimit(backorderLimit, stockQuantity) {
    // console.log(
    //   "Checking backorder limit with:",
    //   backorderLimit,
    //   stockQuantity
    // );
    // Disable the "Add to Cart" button if stock quantity is less than or equal to the backorder limit
    if (stockQuantity <= -backorderLimit) {
      $(".single_add_to_cart_button")
        .prop("disabled", true)
        .addClass("disabled backorder-limit")
        .text("Out of stock");
    } else {
      $(".single_add_to_cart_button")
        .prop("disabled", false)
        .removeClass("disabled backorder-limit");
    }
  }

  // Handle variable products
  $(".variations_form").on("show_variation", function (event, variation) {
    var variationId = variation.variation_id;
    var backorderLimit = parseInt(
      $(
        ".backorder_limit_variation[data-variation-id='" + variationId + "']"
      ).val()
    );
    var stockQuantity = parseInt(
      $(
        ".stock_quantity_variation[data-variation-id='" + variationId + "']"
      ).val()
    );

    // console.log("Variation ID:", variationId);
    // console.log("Backorder Limit:", backorderLimit);
    // console.log("Stock Quantity:", stockQuantity);
    // console.log("Variation Object:", variation);

    var addToCartButton = $(".single_add_to_cart_button");

    // Check if stock quantity matches the backorder limit criteria
    if (stockQuantity <= -backorderLimit) {
      addToCartButton
        .prop("disabled", true)
        .addClass("disabled backorder-limit")
        .text("Out of stock");
    }
    checkBackorderLimit(backorderLimit, stockQuantity);
  });

  // Handle simple products
  if ($(".single-product").length) {
    var backorderLimit = parseInt($("#backorder_limit").val());
    var stockQuantity = parseInt($("#stock_quantity").val());

    // console.log("Simple Product Backorder Limit:", backorderLimit);
    // console.log("Simple Product Stock Quantity:", stockQuantity);

    var addToCartButton = $(".single_add_to_cart_button");

    // Check if stock quantity matches the backorder limit criteria
    checkBackorderLimit(backorderLimit, stockQuantity);
  }

  // Ensure the button text is reset if no variation is selected
  $(".variations_form").on("woocommerce_variation_select_reset", function () {
    $(".single_add_to_cart_button")
      .text("Select Options")
      .prop("disabled", true)
      .removeClass("backorder-limit");
  });
});
