// /* Custom settings for variable products */
// /* Colour swatches product attributes */

jQuery(document).ready(function ($) {
  $(document).on("click", ".variation-buttons .variation-button", function () {
    var $el = $(this);
    var thisName = $el.attr("name");
    var thisVal = $el.attr("value");
    var $productContainer = $el.closest(".product"); // Assuming the product container has the class "product"
    var $colorSelect = $productContainer.find(
      'select[name="' + thisName + '"]'
    );
    var currentVal = $colorSelect.val();

    if (currentVal === thisVal) {
      // Toggle the selection if the same color is clicked again
      $colorSelect.val(thisVal).trigger("change"); // Set the selected attribute value
    } else {
      $productContainer.find(".color-name").text(thisVal);
      $colorSelect.val(thisVal).trigger("change"); // Set the selected attribute value
    }

    // Scroll the user back to the top of the page
    if ($(window).width() < 768) {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        100 // Duration of the animation
      );
    }
  });

  $(document).on("woocommerce_update_variation_values", function () {
    $(".variation-buttons .variation-button").each(function (index, element) {
      var $el = $(element);
      var thisName = $el.attr("name");
      var thisVal = $el.attr("value");
      var $productContainer = $el.closest(".product"); // Assuming the product container has the class "product"
      var $colorSelect = $productContainer.find(
        'select[name="' + thisName + '"]'
      );
      var currentVal = $colorSelect.val();
      $el.removeAttr("disabled");

      if (
        $productContainer
          .find(
            'select[name="' + thisName + '"] option[value="' + thisVal + '"]'
          )
          .is(":disabled")
      ) {
        $el.prop("disabled", true);
      }

      if (currentVal === thisVal) {
        // Update the color name if the color is still selected and not disabled
        $productContainer.find(".color-name").text(thisVal);
      }
    });
  });
});
