jQuery(document).ready(function ($) {
  $("ul.products").addClass("d-flex flex-row flex-wrap justify-content-center");

  // Get the button and the div to expand
  var expandButton = $("#filter-expandable");
  var expandableDiv = $(".prod-archive-sidebar");

  // Add click event listener
  expandButton.click(function () {
    // Toggle the 'expanded' class
    expandableDiv.toggleClass("expanded");
  });
});
